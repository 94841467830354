<template>
  <div>
    <div>
      <img class="top_image" :src="menu.bannerImg" alt=""/>
    </div>
    <!-- 面包屑 -->
    <div class="mbx">
      <div style="width: 1200px; margin: 20px auto">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size:16px">
            <router-link to="/">首页</router-link>
          </el-breadcrumb-item>
          <el-breadcrumb-item style="font-size:16px">公示</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <p class="small_line"></p>
    </div>
    <!-- 公示信息 -->
    <div class="news" style="margin: 0 auto;width: 1200px;">
      <el-tabs v-model="activeName">
        <el-tab-pane label="实际承运人评价公示" name="1">
          <!-- 公示信息列表 -->
          <div class="newscontent">
            <el-table :data="evaluateList" v-loading="loading" style="width: 100%">
              <el-table-column prop="driverName" label="司机姓名"/>
              <el-table-column prop="carNo" label="车牌号"></el-table-column>
              <el-table-column prop="addTime" label="评价时间"></el-table-column>
              <el-table-column prop="evalDetail" label="评价内容" :show-overflow-tooltip="true"></el-table-column>
              <el-table-column label="客户满意度" align="left" prop="evalLevel" width="180">
                <template slot-scope="scope">
                  <el-row>
                    <el-col :span="12">尽职尽责:</el-col>
                    <el-col :span="12"><i :style="item.color" v-for="(item,index) in setStar(scope.row.evalLevel)" :key="index"
                                          :class="item.value"></i></el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">服务一流:</el-col>
                    <el-col :span="12"><i :style="item.color" v-for="(item,index) in setStar(scope.row.evalLevel1)" :key="index"
                                          :class="item.value"></i></el-col>
                  </el-row>
                </template>
              </el-table-column>
              <el-table-column label="运输效率" align="left" prop="efficiencyLevel" width="180">
                <template slot-scope="scope">
                  <el-row>
                    <el-col :span="12">装货及时:</el-col>
                    <el-col :span="12"><i :style="item.color" v-for="(item,index) in setStar(scope.row.efficiencyLevel)"
                                          :key="index" :class="item.value"></i></el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">送货及时:</el-col>
                    <el-col :span="12"><i :style="item.color" v-for="(item,index) in setStar(scope.row.efficiencyLevel1)"
                                          :key="index" :class="item.value"></i></el-col>
                  </el-row>
                </template>
              </el-table-column>
              <el-table-column label="运输安全" align="left" prop="safeLevel" width="180">
                <template slot-scope="scope">
                  <el-row>
                    <el-col :span="12">货物完整:</el-col>
                    <el-col :span="12"><i :style="item.color" v-for="(item,index) in setStar(scope.row.safeLevel)" :key="index"
                                          :class="item.value"></i></el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">规范操作:</el-col>
                    <el-col :span="12"><i :style="item.color" v-for="(item,index) in setStar(scope.row.safeLevel1)" :key="index"
                                          :class="item.value"></i></el-col>
                  </el-row>
                </template>
              </el-table-column>
              <el-table-column label="服务质量" align="left" prop="serverLevel" width="180">
                <template slot-scope="scope">
                  <el-row>
                    <el-col :span="12">礼貌沟通:</el-col>
                    <el-col :span="12"><i :style="item.color" v-for="(item,index) in setStar(scope.row.serverLevel)"
                                          :key="index" :class="item.value"></i></el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">积极响应:</el-col>
                    <el-col :span="12"><i :style="item.color" v-for="(item,index) in setStar(scope.row.serverLevel1)"
                                          :key="index" :class="item.value"></i></el-col>
                  </el-row>
                </template>
              </el-table-column>
            </el-table>
            <pagination
                v-show="total>0"
                :total="total"
                :page.sync="pageNum"
                :limit.sync="pageSize"
                @pagination="getSupply"
            />
          </div>
        </el-tab-pane>
        <el-tab-pane label="司机咨询投诉处理及满意度公示" name="2">
          <div class="news">
            <!-- 公示信息列表 -->
            <div class="newscontent">
              <el-table :data="evaluateList1" style="width: 100%">
                <el-table-column prop="waybillSn" label="运单"/>
                <el-table-column prop="evalByInfo" label="投诉咨询人"/>
                <el-table-column prop="evalType" label="类型">
                  <template slot-scope="scope">
                    <span v-if="scope.row.evalType==1">评价</span>
                    <span v-if="scope.row.evalType==2">咨询</span>
                    <span v-if="scope.row.evalType==3">投诉</span>
                  </template>
                </el-table-column>
                <el-table-column prop="evalToInfo" label="对象"/>
                <el-table-column prop="addTime" label="发起时间"/>
                <el-table-column prop="updateTime" label="处理时间"/>
                <el-table-column prop="status" label="处理状态">
                  <template slot-scope="scope">
                    <span v-if="scope.row.status==1">待处理</span>
                    <span v-if="scope.row.status==2">已回复</span>
                    <span v-if="scope.row.status==3">已办结</span>
                  </template>
                </el-table-column>
                <el-table-column prop="evalLevel" label="满意度">
                  <template slot-scope="scope">
                    <i :style="item.color" v-for="(item,index) in setStar(scope.row.evalLevel)" :key="index" :class="item.value"></i>
                  </template>
                </el-table-column>
              </el-table>
              <pagination
                  v-show="total1>0"
                  :total="total1"
                  :page.sync="pageNum1"
                  :limit.sync="pageSize1"
                  @pagination="getEvaluateList1"
              />
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane v-for="(item,index) in subMenu" :key="index" :label="item.name" :name="index+3+''">
          <p class="ql-editor contentdel" v-html="item.content" style="text-algin: left"></p>
        </el-tab-pane>
      </el-tabs>

    </div>
  </div>
</template>

<script>
import Pagination from "../components/pagination";
import axios from 'axios'
import {getContentList, getMenuList} from "@/request";

export default {
  components: {
    Pagination
  },
  data() {
    return {
      subMenu: [],
      activeName:'1',
      menu: {
        bannerImg: ''
      },
      loading: true,
      pageNum: 1,
      pageSize: 10,
      total: 0,
      pageNum1: 1,
      pageSize1: 10,
      total1: 0,
      evaluateList: [],
      evaluateList1: []
    }
  },
  created() {
    getMenuList({path: this.$route.path}).then(res => {
      this.menu = res.data[0];
      getMenuList({parentId: this.menu.id}).then(res => {
        this.subMenu = res.data;
        for (let i = 0; i < this.subMenu.length; i++) {
          getContentList({menuId: this.subMenu[i].id}).then(res1 => {
            if(res1.rows.length>0){
              let content = res1.rows[0].content;
              this.$set(this.subMenu[i], 'content', content)
            }
          });
        }
      });
    });
    this.getSupply();
    this.getEvaluateList1();
  },
  mounted() {
  },
  computed: {},
  methods: {
    getSupply() {
      axios.get(window.apiUrl + "/api/common/evaluateList?pageSize="+this.pageSize+"&pageNum="+this.pageNum).then(res => {
        this.total = res.data.total;
        this.evaluateList = res.data.rows;
        this.loading = false;
      });
    },
    getEvaluateList1(){
      axios.get(window.apiUrl + "/api/common/evaluateList1?pageSize="+this.pageSize+"&pageNum="+this.pageNum).then(res => {
        this.total1 = res.data.total;
        this.evaluateList1 = res.data.rows;
        console.log(this.evaluateList1);
        this.loading = false;
      });
    },
    openDetails(row){
      this.$router.push({name: '货源详情', params: {id: row.SUPPLY_ID}});
    },
    setStar(data) {
      let arr = [];
      if (data === 0) {
        for (let i = 1; i < 6; i++) {
          arr.push({
            index: i,
            value: 'el-icon-star-off',
          });
          if (i + 1 === data) return arr;
        }
      } else {
        for (let i = 1; i < 6; i++) {
          if (i > data) {
            arr.push({
              index: i,
              value: 'el-icon-star-off',
              color: 'color:rgba(0,0,0,0.15)',
            });
          } else {
            arr.push({
              index: i,
              value: 'el-icon-star-on',
              color: 'color:rgb(247, 186, 42)',
            });
          }
          if (i + 1 === 6) return arr;
        }
      }
    },
  }
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

.small_line {
  background: #ddd;
  height: 1px;
}

.ywx_content_line1 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.bews_line {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.top_image {
  width: 100%;
}

.fgx {
  width: 1200px;
  margin: 0 auto;
}

.fgx .title {
  font-weight: 900;
  text-align: left;
  margin-top: 30px;
  font-size: 20px;
  margin-bottom: 10px;
}

.fgx .line {
  width: 100%;
  height: 5px;
  background-color: #efefef;
}

.line .linebg {
  height: 5px;
  width: 80px;
  background-color: black;
}

.http {
  width: 700px;
  margin: 0 auto;
  border: 1px solid #dddddd;
  height: 30px;
  margin-bottom: 100px;
  line-height: 30px;
}

.bigline {
  height: 1px;
  width: 1200px;
  margin: 10px auto;
  background-color: #dddddd;
}

.news .newscontent {
  margin: 0 auto;
  width: 1200px;
  margin-top: 30px;
  text-align: left;
  padding-bottom: 50px;
  text-justify: inter-word;
  background: url("../../static/切图文件/bg-map-1.png") no-repeat top center;
}

.news .newscontent .news_content {
  display: inline-block;
  width: 358px;
  height: 240px;
  margin-left: 20px;
  margin-right: 20px;
  border: 1px solid #ddd;
  margin-top: 20px;
  margin-bottom: 30px;
  vertical-align: top;
}

.news .newscontent .news_content .mdimgcontent {
  width: 358px;
  height: 180px;
  position: relative;
}

.news .newscontent .news_content .dw {
  margin: 0;
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 99;
  text-align: left;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
}

.news .newscontent .news_content:hover {
  box-shadow: 0 0 20px #aaa;
}

.downbtn {
  margin-top: 30px;
  height: 40px;
  text-align: center;
  /* transform: translate(-52px); */
}

.jiazaimes {
  font-size: 18px;
  color: rgb(249, 145, 0);
}
</style>